import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import { Box, Columns, Column, Stack, Tiles } from '@nib/layout';
import { SuccessGraphicIcon } from '@nib/icons';
import ImagerySignoff from '../../src/components/imagery-signoff/index.js';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SecondaryButton = makeShortcode("SecondaryButton");
const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Usage`}</h2>
    <p>{`This documentation covers guidelines for practical usage of imagery across our digital spaces and experiences, including whitelabel partners. These guidelines are an important foundation for onboarding and empowering our Designers, Developers and project teams to develop projects easily, effectively, creatively and efficiently.`}</p>
    <p>{`For further guidelines around aligning photography and illustration selection with nib's digital brand, refer to our Brand Imagery documentation.`}</p>
    <SecondaryButton href="/brand/imagery/" mdxType="SecondaryButton">nib Brand Imagery Guidelines</SecondaryButton>
    <h3 {...{
      "id": "formats",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#formats",
        "aria-label": "formats permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Formats`}</h3>
    <h4 {...{
      "id": "photography",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#photography",
        "aria-label": "photography permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Photography`}</h4>
    <p>{`Photography should use `}<inlineCode parentName="p">{`.jpg`}</inlineCode>{` format, with appropriate resolution and compression to meet the guidelines in the table below.`}</p>
    <h4 {...{
      "id": "logos-graphics--illustrations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#logos-graphics--illustrations",
        "aria-label": "logos graphics  illustrations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Logos, graphics `}{`&`}{` illustrations`}</h4>
    <p>{`Use `}<inlineCode parentName="p">{`.png`}</inlineCode>{` or `}<inlineCode parentName="p">{`.svg`}</inlineCode>{` format for logos, graphics & illustrations, to preserve alpha-channel (transparent) backgrounds. Note that `}<inlineCode parentName="p">{`.png`}</inlineCode>{` files can only be scaled down while preserving detail, so in situations where images may be responsively scaled, `}<inlineCode parentName="p">{`.svg`}</inlineCode>{` is preferred. These assets should not contain filled backgrounds, unless it is a pre-defined feature of the asset (e.g. boxed logos). `}</p>
    <p>{`When exporting `}<inlineCode parentName="p">{`.png`}</inlineCode>{` files, aim to meet the resolutions and file sizes denoted in the table below. Note that `}<inlineCode parentName="p">{`.png`}</inlineCode>{` files do not support variable or lossy file compression, consider the impact of large images on page-load times.`}</p>
    <h3 {...{
      "id": "resolutions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#resolutions",
        "aria-label": "resolutions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Resolutions`}</h3>
    <p>{`The following table is drawn from our `}<a parentName="p" {...{
        "href": "/components/hero-panel"
      }}>{`Hero Panel`}</a>{` component - as our most common full-width component that uses photographic imagery, it can be used as a guideline for approximating resolutions and file sizes for image assets. To ensure image quality across responsive viewports, we recommend creating assets at @2x (Retina) resolution and scaling down for non-retina displays. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Image`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Dimensions`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`File size limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Desktop@2x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`4000x1920`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`400kb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Desktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2000x960`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`150kb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tablet@2x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1600x1160`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`150kb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tablet`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`800x580`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`60kb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mobile@2x`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`800x600`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`50kb`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mobile`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`400x300`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`25kb`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "library-access-ownership--licensing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#library-access-ownership--licensing",
        "aria-label": "library access ownership  licensing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Library access, ownership `}{`&`}{` licensing`}</h3>
    <p>{`nib's Design Communications Team maintains a database of brand-aligned photography and miscellaneous images, sourced and housed through nib's `}<a parentName="p" {...{
        "href": "https://www.gettyimages.com.au/"
      }}>{`GettyImages`}</a>{` account. Getty provides useful, in-built export tools to generate context-specific crops in a variety of pre-loaded and custom sizes and formats, or alternatively, full-resolution images can be downloaded for flexible use within your projects.`}</p>
    <p>{`Approved teams or individuals will have a dedicated login for our image library. To gain access, or if you can't find an image appropriate for your context, email `}<a parentName="p" {...{
        "href": "mailto:designcommunications@nib.com.au"
      }}>{`designcommunications@nib.com.au`}</a>{`.`}</p>
    <SecondaryButton href="https://dam.gettyimages.com/nib" mdxType="SecondaryButton">GettyImages library</SecondaryButton>
    <h3 {...{
      "id": "whitelabel-partners",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whitelabel-partners",
        "aria-label": "whitelabel partners permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Whitelabel partners`}</h3>
    <p>{`In contexts where we are providing content for nib's whitelabel partners, we should ensure that imagery choices reflect each respective partner's brand guidelines. Where this imagery has not been supplied, contact your team leader or liaison for direction on sourcing appropriate imagery for whitelabel projects.`}</p>
    <h3 {...{
      "id": "using-text-and-imagery-together",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-text-and-imagery-together",
        "aria-label": "using text and imagery together permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Using text and imagery together`}</h3>
    <p>{`To ensure accessibility and readability, text copy should never be placed directly over imagery - including headings. In situations where this is unavoidable, use card-style containers with a solid background color to enclose text copy. Refer to our `}<a parentName="p" {...{
        "href": "/foundations/colors/"
      }}>{`color guidelines`}</a>{` for documentation on how to ensure accessibility when determining text and background color combinations.`}</p>
    <ImagerySignoff mdxType="ImagerySignoff" />
    <h2 {...{
      "id": "diversity-and-inclusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#diversity-and-inclusion",
        "aria-label": "diversity and inclusion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Diversity and inclusion`}</h2>
    <p>{`The primary intent of how we use imagery is to create a touchpoint for our members and prospects, allowing them to project themselves into the lifestyle and outcomes we promote through our products and services. With that in mind, it's critical we consider the breadth of identity and lived experiences within our member base, and our imagery choices should reflect that diversity. `}</p>
    <p>{`nib's `}<a parentName="p" {...{
        "href": "https://www.nib.com.au/docs/diversity-and-inclusion-action-plan-2021"
      }}>{`Diversity and Inclusion Action Plan`}</a>{` recognizes Diversity and Inclusion as follows: `}</p>
    <Tiles columns={{
      xs: 1,
      md: 2
    }} space={0} flex mdxType="Tiles">
      <Box background="warmWhite" padding={7} mdxType="Box">
        <Stack space={5} mdxType="Stack">
          <h3 {...{
            "id": "diversity",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h3" {...{
              "href": "#diversity",
              "aria-label": "diversity permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "width": "16px",
                "height": "16px",
                "viewBox": "0 0 24 24",
                "version": "1.1",
                "xmlns": "http://www.w3.org/2000/svg",
                "xmlnsXLink": "http://www.w3.org/1999/xlink"
              }}>{`
                `}<g parentName="svg" {...{
                  "id": "Icon/System/Hyperlink",
                  "stroke": "none",
                  "strokeWidth": "1",
                  "fill": "none",
                  "fillRule": "evenodd"
                }}>{`
                    `}<g parentName="g" {...{
                    "id": "bounding-box"
                  }}>{`
                        `}<polygon parentName="g" {...{
                      "id": "shape",
                      "points": "0 0 24 0 24 24 0 24"
                    }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                    "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                    "id": "Combined-Shape",
                    "fill": "#444444",
                    "fillRule": "nonzero"
                  }}></path>{`
                `}</g>{`
              `}</svg></a>{`Diversity`}</h3>
          <p>{`Diversity spans across lived, personal and professional experience. It covers our full spectrum of differences - be that gender, disability, race, ethnicity, nationality, language, sexual orientation, family status, age, religion, background, capabilities and skills.`}</p>
        </Stack>
      </Box>
      <Box background="warmWhite60" padding={7} mdxType="Box">
        <Stack space={5} mdxType="Stack">
          <h3 {...{
            "id": "inclusion",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h3" {...{
              "href": "#inclusion",
              "aria-label": "inclusion permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "width": "16px",
                "height": "16px",
                "viewBox": "0 0 24 24",
                "version": "1.1",
                "xmlns": "http://www.w3.org/2000/svg",
                "xmlnsXLink": "http://www.w3.org/1999/xlink"
              }}>{`
                `}<g parentName="svg" {...{
                  "id": "Icon/System/Hyperlink",
                  "stroke": "none",
                  "strokeWidth": "1",
                  "fill": "none",
                  "fillRule": "evenodd"
                }}>{`
                    `}<g parentName="g" {...{
                    "id": "bounding-box"
                  }}>{`
                        `}<polygon parentName="g" {...{
                      "id": "shape",
                      "points": "0 0 24 0 24 24 0 24"
                    }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                    "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                    "id": "Combined-Shape",
                    "fill": "#444444",
                    "fillRule": "nonzero"
                  }}></path>{`
                `}</g>{`
              `}</svg></a>{`Inclusion`}</h3>
          <p>{`Inclusion means everyone is treated equitably, where all people are provided with opportunities to succeed and thrive in their careers. We see this as crucial, not only to our success, but in creating a more connected world.`}</p>
        </Stack>
      </Box>
    </Tiles>
    <p>{`To engage with these definitions, it's important that our selections consider both the visible and invisible markers of peoples' culture, heritage, gender, sexuality, identity or disability, and embrace a diversity of lived experiences and idealised outcomes. It is equally important to recognise and consider intersectionality when selecting imagery, ensuring it reflects the mutiple factors of identity that make up the diverse fabric of the communities in which we operate.`}</p>
    <p>{`For further insight relating to diverse imagery and adopting an inclusive mindset when making visual selections, refer to ForumOne's `}<a parentName="p" {...{
        "href": "https://www.forumone.com/ideas/how-to-choose-diverse-and-inclusive-photos/"
      }}>{`How to Choose Diverse and Inclusive Photos`}</a>{`.`}</p>
    <Box background="sageGreen" padding={7} mdxType="Box">
      <Stack space={5} mdxType="Stack">
        <h3 {...{
          "id": "di-dos",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#di-dos",
            "aria-label": "di dos permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "width": "16px",
              "height": "16px",
              "viewBox": "0 0 24 24",
              "version": "1.1",
              "xmlns": "http://www.w3.org/2000/svg",
              "xmlnsXLink": "http://www.w3.org/1999/xlink"
            }}>{`
                `}<g parentName="svg" {...{
                "id": "Icon/System/Hyperlink",
                "stroke": "none",
                "strokeWidth": "1",
                "fill": "none",
                "fillRule": "evenodd"
              }}>{`
                    `}<g parentName="g" {...{
                  "id": "bounding-box"
                }}>{`
                        `}<polygon parentName="g" {...{
                    "id": "shape",
                    "points": "0 0 24 0 24 24 0 24"
                  }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                  "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                  "id": "Combined-Shape",
                  "fill": "#444444",
                  "fillRule": "nonzero"
                }}></path>{`
                `}</g>{`
              `}</svg></a>{`D`}{`&`}{`I Do's`}</h3>
        <ul>
          <li parentName="ul">{`Ensure your image selections collectively represent both visible and invisible markers of subjects' and users' culture and heritage, gender and sexuality, ability or disability, and lived experiences. `}</li>
          <li parentName="ul">{`Consider the cumulative effect of imagery selections across parallel pages or sequentially through end-to-end experiences and user flows.`}</li>
          <li parentName="ul">{`Embrace intersectionality in your selections, considering multiple identity touchpoints for each image`}</li>
          <li parentName="ul">{`Consider unconscious bias when making your selections `}</li>
        </ul>
      </Stack>
    </Box>
    <Box background="sunsetPink" padding={7} mdxType="Box">
      <Stack space={5} mdxType="Stack">
        <h3 {...{
          "id": "di-donts",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#di-donts",
            "aria-label": "di donts permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "width": "16px",
              "height": "16px",
              "viewBox": "0 0 24 24",
              "version": "1.1",
              "xmlns": "http://www.w3.org/2000/svg",
              "xmlnsXLink": "http://www.w3.org/1999/xlink"
            }}>{`
                `}<g parentName="svg" {...{
                "id": "Icon/System/Hyperlink",
                "stroke": "none",
                "strokeWidth": "1",
                "fill": "none",
                "fillRule": "evenodd"
              }}>{`
                    `}<g parentName="g" {...{
                  "id": "bounding-box"
                }}>{`
                        `}<polygon parentName="g" {...{
                    "id": "shape",
                    "points": "0 0 24 0 24 24 0 24"
                  }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
                  "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
                  "id": "Combined-Shape",
                  "fill": "#444444",
                  "fillRule": "nonzero"
                }}></path>{`
                `}</g>{`
              `}</svg></a>{`D`}{`&`}{`I Don'ts`}</h3>
        <ul>
          <li parentName="ul">{`Don't rely solely on visible or obvious markers to denote a subject(s)' culture, heritage, identity or background. `}</li>
          <li parentName="ul">{`Avoid context or usage that leans on cultural generalisations or stereotypes. `}</li>
          <li parentName="ul">{`Avoid one-dimensional representation of identity markers`}</li>
          <li parentName="ul">{`Don't rely on majority demographics or implied identity 'defaults' `}</li>
        </ul>
      </Stack>
    </Box>
    <h3 {...{
      "id": "alt-text",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alt-text",
        "aria-label": "alt text permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Alt text`}</h3>
    <p>{`As well as being an importance practice for accessibility reasons, considering alt text for your image choices is an excellent method of validating what you are depicting, ensuring its thematic link to your content, and identifying any incidental biases that may have gone into its selection. `}</p>
    <p>{`Alt text should descriptively and specifically communicate what is depicted, so by anticipating its wording and context, you can ensure your selections are relevant, inclusive and reflective of users' lived experiences.`}</p>
    <p>{`For more information about accessible and effective usage of alt text, refer to our `}<a parentName="p" {...{
        "href": "/getting-started/accessibility/accessibility-checklist#1-correct-use-of-alt-attributes"
      }}>{`Accessibility Checklist`}</a>{`. `}</p>
    <h3 {...{
      "id": "image-captioning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#image-captioning",
        "aria-label": "image captioning permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Image captioning`}</h3>
    <h4 {...{
      "id": "purpose",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#purpose",
        "aria-label": "purpose permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Purpose`}</h4>
    <p>{`Image captioning serves a key role to ensure that everyone can access and understand the visual content displayed within an image. The added descriptions can include additional information that might not be clear in the image itself.`}</p>
    <h4 {...{
      "id": "usage-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#usage-1",
        "aria-label": "usage 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Usage`}</h4>
    <p>{`Image captions are text that appear adjacent to an image, the positioning is bottom left of an image (for web images only). For hero / villain panels, the image caption appears on the bottom of the panel cards. `}</p>
    <p>{`Image captions differ to alternative text as they serve two different purpose: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`"alt"`}</strong>{` text should always be included with a non-decorative image.  They provide a concise description of an image for accessibility purposes. `}</li>
      <li parentName="ul"><strong parentName="li">{`"image caption"`}</strong>{`  text on the other hand, are optional and should only be used when we need to add additional context or information related to the image content. `}</li>
    </ul>
    <Alert title="Image caption to be treated as secondary to alt text" mdxType="Alert">
Alt text for images will take priority in terms of fulfilling our accessibility checks and compliance with WCAG. We advise that image captioning only to be used when appropriate to provide additional context to imagery.
    </Alert>
    <h4 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#examples",
        "aria-label": "examples permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Examples`}</h4>
    <p>{`We've added a prop to the `}<a parentName="p" {...{
        "href": "/components/hero-panel"
      }}>{`Hero Panel`}</a>{` `}{`&`}{` `}<a parentName="p" {...{
        "href": "/components/villain-panel"
      }}>{`Villain Panel`}</a>{` components. To explore and visualise how this config changes are implemented click through the links below to view some of our use case examples: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/storybook/?path=/story/components-copy--image-caption"
        }}>{`Storybook Example`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/playroom/#?code=N4Igxg9gJgpiBcIA8AhCAPABABwIZSgEsA7AcwF5gA2AXwD4AdYzTJAZQBdcwBrTAZzxgYlAEw1MufuQYgAZoVIBXAE4xZjZi1YAFADbcYACwh7YKzEZiKjHGSACMAVjUBbWZgD0m7awCSrrikMADCuNgchBDMUvYKpGDhkdEaTL4sbBCqwvCSeoQAjkowHJhKegaukCrYMBZG3JjEuK6SmABGhO0wxFBKrbjEpfgChMOYcjBKpIS4HGnpJbOttRU9HDD8RTAAdAssSJ4BQaFJUcQ%2BrJ6c3Dyah2jomiAANCAcVq6bCADaIMRdEAAXTeAHdCFAPvxfgBmADsThecKoAA4Xg4AAyiAAs6Ox2IxQJoQA"
        }}>{`Playroom Example`}</a></li>
    </ul>
    <h2 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Contact`}</h2>
    <p>{`For further information and questions regarding the use of imagery please contact the nib Design Communications team via email at `}<a parentName="p" {...{
        "href": "mailto:designcommunications@nib.com.au"
      }}>{`designcommunications@nib.com.au`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      